import React from 'react'
export default function Eflyers () {
  return (
    <div style={{ margin: 18 }}>
      <h1>E-Flyer : English</h1>
      <tr>

	  <li>

	    <a href="https://e-flyers.s3.us-west-2.amazonaws.com/2024+September+Specials.pdf" target="_blank" rel='noreferrer'>
             Atlas September Specials Flyer 2024
            </a>

          </li>
	  <li>

	    <a href="https://e-flyers.s3.us-west-2.amazonaws.com/2024+Sept+Antifreeze+Flyer.pdf" target="_blank" rel='noreferrer'>
             September Antifreeze Flyer 2024
            </a>

          </li>
	  <li>

	    <a href="https://e-flyers.s3.us-west-2.amazonaws.com/2024+Sept+ADCO+Covers+Specials+English.pdf" target="_blank" rel='noreferrer'>
             Adco Cover Specials - September 2024
            </a>

          </li>

	
	</tr>
      <div style={{ margin: 16 }}>
        <h1>E-Flyer : French</h1>
        <tr>
          
 	 <li>
           
	    <a href="https://e-flyers.s3.us-west-2.amazonaws.com/2024+September+Specials+French.pdf" target="_blank" rel='noreferrer'>
             Dépliant des Offres Spéciales Atlas - Septembre 2024
            </a>
            
        </li> 
	<li>
           
	    <a href="https://e-flyers.s3.us-west-2.amazonaws.com/2024+Sept+Antifreeze+Flyer+FRENCH.pdf" target="_blank" rel='noreferrer'>
             Septembre Antigel Flyer 2024
            </a>
            
        </li>	

	<li>
           
	    <a href="https://e-flyers.s3.us-west-2.amazonaws.com/2024+Sept+ADCO+Covers+Specials+French.pdf" target="_blank" rel='noreferrer'>
             Adco Cover Specials - septembre 2024
            </a>
            
        </li>
	  
	 </tr>
      </div>
    </div>
  )
}
