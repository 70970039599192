import React from 'react'

export default function Marketing () {
  return (
    <div style={{ margin: 18 }}>
      <h1>Marketing [English]</h1>
      <div>
        <ul>

          <li>
            <a href="https://rvcatalogue.s3.amazonaws.com/2024+ConsumerNet+Retail+Marketing.pdf" target="_blank" rel='noreferrer'>
              2024 ConsumerNet Retail Marketing Program
            </a>
          </li>

          <li>
            <a href="https://rvcatalogue.s3.amazonaws.com/2024+RVPF+page+w+Subscription.pdf" target="_blank" rel='noreferrer'>
              2024 RV Partfinder Brochure & Subscription Agreement
            </a>
          </li>

          <li>
            <a href="https://e-flyers.s3.us-west-2.amazonaws.com/Website+Access+Request+fillable.pdf" target="_blank" rel='noreferrer'>
              Website Access Request Form
            </a>
          </li>

          <li>
            <a href="https://atlastrailer.s3.amazonaws.com/03-Carded%20Products.jpg" target="_blank" rel='noreferrer'>
              Carded Products (Brochure)
            </a>
          </li>


        </ul>


        <div style={{ margin: 18 }}>
          <h1>Commercialisation [French]</h1>
          <div>
            <ul>

              <li>
                <a href="https://rvcatalogue.s3.amazonaws.com/2024+ConsumerNet+Retail+Marketing+FRENCH.pdf" target="_blank" rel='noreferrer'>
                  2024 Programme de marketing de détail de ConsumerNet
                </a>
              </li>

              <li>
                <a href="https://rvcatalogue.s3.amazonaws.com/2024+RVPF+page+w+Subscription+FRENCH.pdf" target="_blank" rel='noreferrer'>
                  Accord de souscription 2024 RV Partfinder
                </a>
              </li>

              <li>
                <a href="https://e-flyers.s3.us-west-2.amazonaws.com/Website+Access+Request+French.pdf" target="_blank" rel='noreferrer'>
                  Formulaire de demande d'accès au site web
                </a>
              </li>

              <li>
                <a href="https://s3.amazonaws.com/atlastrailer.com/Carded+Product+Brochure+FRENCH.pdf" target="_blank" rel='noreferrer'>
                  Produits Cartonés (Brochure)
                </a>
              </li>


            </ul>
          </div>
        </div>
      </div>
    </div>
  )

}
